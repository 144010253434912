<template>
    <div class="container-fluid" v-if="hasPartners">

        <hr class="my-4"/>

        <div class="row">

            <div class="col-1 text-end">
                <template v-if="showMainLoading">
                    <ScIcon name="spinnerSmall" class="m-2 text-muted"/>
                </template>
            </div>

            <div class="col-11">
                <h4>Partner Workshop Access</h4>
                <div>
                    <small>Add partner workshops in
                        <router-link :to="{ name: 'settings-workshop',
                          params: { workshopId: mainMxCurrentWorkshopId }}">workshop settings</router-link>.
                    </small>
                </div>

                <form class="mt-3">
                    <div :class="['row partner-workshops-list clearfix', !showMainLoading && hasPartners ? '' : 'hidden']">
                        <div class="col-12">
                            <ManageAccessPartnersPartner
                                    v-for="(p, idx) in partners" :key="idx"
                                    :partner="p"
                                    :workspaceId="workspaceId"
                                    :presentationId="presentationId"
                                    @refresh-partner-data="loadPartnerData()"
                                    @update-partner-data="updatePartnerData"
                                    @update-partner-to-latest="updatePartnerToLatest">
                            </ManageAccessPartnersPartner>
                        </div>

                    </div>
                </form>

                <div v-if="!showMainLoading" class="pt-4">
                    <small><ScIcon name="questionCircle" class="text-muted"/>
                        Partner workshop can’t see the files from this presentation in their File Library.
                        <br/>Initially, this will only be visible to Editors and Admins in the partner workshop.
                        Once they have previewed the presentation, they can then allow their own users to see it,
                        using ‘Manage Access’.
                    </small>
                </div>
            </div>

        </div>
    </div>


</template>


<script>


import ScNotification from '../../../shared/common/ScNotification.vue';
import ScIcon from '../../../shared/common/ScIcon.vue';
import $ from 'jquery';
import MainAppMixin from '../../MainAppMixin';
import ManageAccessPartnersPartner from './ManageAccessPartnersPartner.vue';

let DataManager = {
    loadPartnerData (vm) {
        vm.showMainLoading = true;
        $.ajax({
            url: '/main/partners/ajax_partners_get',
            data: {workspace_id: vm.workspaceId, presentation_id: vm.presentationId}

        }).done((data) => {
            vm.partners = data.partners;
            vm.hasPartners = data.partners && data.partners.length > 0;

        }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, "loading data");

        }).always(() => {
            vm.showMainLoading = false;
        });
    },

};



export default {
    name: 'ManageAccessPartnersList',
    emits: ['hide-manage-access'],
    mixins: [MainAppMixin],
    components: {ManageAccessPartnersPartner, ScIcon},
    props: {
        workspaceId: {type: Number, default: null},
        presentationId: {type: Number, default: null}
    },
    data () {
        return {
            showMainLoading: false,
            partners: [],
            hasPartners: false
        };
    },
    mounted () {
        this.loadPartnerData();

    },
    methods: {
        loadPartnerData () {
            DataManager.loadPartnerData(this);
        },
        updatePartnerData (data) {
            for (let item of Object.values(this.partners)) {
                if (item.partner_workspace_id === data.partner_workspace_id) {
                    item.partner_enabled = data.enabled;
                    item.trigger_update_manually = data.trigger_update_manually;
                }
            }
        },
        updatePartnerToLatest (data) {
            for (let item of Object.values(this.partners)) {
                if (item.partner_workspace_id === data.partner_workspace_id) {
                    item.partner_has_version = data.partner_has_version;
                    item.partner_publish_timeout = data.partner_publish_timeout;
                }
            }
        }
    },

};


</script>