<template>
    <div class="container-fluid">

        <hr class="my-4"/>

        <div class="row">
            <div class="col-1 px-0 pt-2">
                <ScToggleOnOff :active="!!forceDownload"
                               :loading="showLoading"
                               @sc-toggle="toggleDownload()"></ScToggleOnOff>
            </div>
            <div class="col-11">
                <h4>Auto Update</h4>
                <small class="d-block">When enabled, updates to this Presentation will be downloaded automatically.</small>
            </div>
        </div>

        <div v-if="forceDownload !== null && showRadios" class="row">
            <div class="offset-1 col-11">
                <br/>
                <div class="form-check">
                    <input type="radio" id="force-download-all-wifi" name="force-download-all" value="wifi"
                           class="me-1 form-check-input"
                           :disabled="showLoading"
                           :checked="forceDownload === 'wifi'" v-on:click="changeType('wifi')">
                    <label for="force-download-all-wifi" class="form-check-label">Wi-fi</label>
                </div>
                <div class="form-check">
                    <input type="radio" id="force-download-all-all" name="force-download-all" value="all"
                           class="me-1 form-check-input"
                           :disabled="showLoading"
                           :checked="forceDownload === 'all'" v-on:click="changeType('all')">
                    <label for="force-download-all-all" class="form-check-label">Wi-fi and Cellular</label>
                    <small>
                        &nbsp;&nbsp;<ScIcon name="exclamationTriangleFW" class="text-danger ms-2"/>&nbsp;Cellular charges will apply, proceed cautiously.
                    </small>
                </div>
            </div>
        </div>

    </div>
</template>


<script>

import ScNotification from '../../../shared/common/ScNotification.vue';
import ScIcon from '../../../shared/common/ScIcon.vue';
import ScToggleOnOff from '../../../shared/common/ScToggleOnOff.vue';
import $ from 'jquery';


let DataManager = {
    loadData (vm) {
        vm.showLoading = true;
        vm.forceDownload = null;
        $.ajax({
            url: "/main/presentations/ajax_showcase_settings_get",
            data: {workspace_id: vm.workspaceId, presentation_id: vm.presentationId}

        }).done(function(data){
            vm.forceDownload = data.force_download;

        }).fail(function(jqXhr) {
            ScNotification.growlXhrError(jqXhr, "loading data");

        }).always(function() {
            vm.showLoading = false;
        });

    },
    updateData (vm) {
        vm.showLoading = true;
        $.ajax({
            type: 'post',
            url: "/main/presentations/ajax_showcase_settings_put",
            data: JSON.stringify({
                workspace_id: vm.workspaceId,
                presentation_id: vm.presentationId,
                force_download: vm.forceDownload
            }),
            contentType: 'application/json'

        }).done(function(data){
            vm.forceDownload = data.force_download;
            ScNotification.growlSuccessMsg('Saved.');

        }).fail(function() {
            ScNotification.growlErrMsg('Change NOT saved.  Please reload this page and try again.  If problem continues please contact support.');

        }).always(function() {
            vm.showLoading = false;
        });

    }
};


export default {
    name: 'ManageAutoUpdate',
    components: {ScToggleOnOff, ScIcon},
    props: {
        workspaceId: {type: Number, default: null},
        presentationId: {type: Number, default: null}
    },
    data () {
        return {
            showLoading: false,
            forceDownload: null
        };
    },
    mounted: function() {
        DataManager.loadData(this);

    },
    computed: {
        showRadios () {
            return this.forceDownload !== null;
        }
    },
    methods: {
        toggleDownload () {
            this.forceDownload = this.forceDownload === null ? 'wifi' : null;
            DataManager.updateData(this);
        },
        changeType (val) {
            this.forceDownload = val;
            DataManager.updateData(this);
        }
    },
};


</script>