<template>
    <div v-if="!showLoading" class="container-fluid">

        <hr class="my-4"/>

        <div class="row">
            <div class="col-1 px-0 pt-2">
                <ScToggleOnOff v-if="!presIsTemplate" :active="permissionType !== 'group-read'"
                               :loading="showLoading"
                               @sc-toggle="changePermission()"></ScToggleOnOff>
            </div>
            <div class="col-11">
                <h4>Limit View Access</h4>
                <div v-if="presIsTemplate">
                    <small>This presentation is a template and is not visible to users.</small>
                </div>

                <div v-if="!presIsTemplate">
                <div>
                    <small class="d-block">
                      By default presentations can be viewed by any user in the workshop.
                      Limiting access will allow only specified groups and/or users to view.</small>
                </div>
                <div v-if="!showLoading && permissionType !== 'group-read'" id="user-and-groups">
                    <div class="mt-2 mb-3">
                        <strong>Allowed to View:</strong>
                        <ScIcon v-if="dataUpdateSpinner" name="spinnerFW" class="ms-2 text-muted" />

                        <div>
                            <div class="btn btn-outline-secondary active border me-2 mt-2"
                                 v-sc-tooltip="'Users with the Admin role'">
                              <ScIcon name="userCowboyFW"/> Admins</div>
                            <div class="btn btn-outline-secondary active border me-2 mt-2"
                                 v-sc-tooltip="'Users with the Editor role'">
                              <ScIcon name="userNinjaFW"/> Editors</div>
                            <div class="btn btn-outline-secondary active border me-2 mt-2"
                                 v-sc-tooltip="'Users with the Manager role'">
                              <ScIcon name="userAstronautFW"/> Managers</div>
                            <div class="btn btn-outline-secondary active border me-2 mt-2"
                                 v-sc-tooltip="'Users with the Reporter role'">
                              <ScIcon name="userBountyHunterFW"/> Reporters</div>

                            <button v-for="(item, idx) in userGroupsSelected" :key="'mav-g-' + idx"
                                    type="button" class="btn btn-outline-secondary me-2 mt-2">
                              <ScIcon name="users" class="me-2" v-sc-tooltip="'Users in this group'" />
                              <router-link v-if="mainMxUwCanPerform('a:list_users')"
                                    :to="{name: 'user-group-user-list', params: {workshopId: mainMxCurrentWorkshopId, groupId: item.id}}"
                                    style="white-space: break-spaces;">{{ item.showName }}</router-link>
                                <span v-else>{{ item.showName }}</span>
                               <span class="ms-2 p-0 m-0 text-muted" style="float:none; position:relative;"
                                      v-sc-tooltip="'Remove'" @click.prevent="deselectGroup(item.id)">&times;</span>
                            </button>

                            <button v-for="(item, idx) in usersSelected" :key="'mav-u-' + idx"
                                    type="button" class="btn btn-outline-secondary me-2 mt-2">
                              <span class="me-2" v-sc-tooltip="'User'">
                                <ScIcon v-if="item.roleCode === 'r:viewer'" name="userFW"/>
                                <ScIcon v-if="item.roleCode === 'r:editor'" name="userNinjaFW"/>
                                <ScIcon v-if="item.roleCode === 'r:manager'" name="userAstronautFW"/>
                                <ScIcon v-if="item.roleCode === 'r:reporter'" name="userBountyHunterFW"/>
                                <ScIcon v-if="item.roleCode === 'r:admin'" name="userCowboyFW"/>
                              </span>
                              <router-link v-if="mainMxUwCanPerform('a:list_users')"
                                    :to="{name: 'user-view', params: {workshopId: mainMxCurrentWorkshopId, userId: item.id}}"
                                    style="white-space: break-spaces;">{{ item.showName }}</router-link>
                               <span v-else style="white-space: break-spaces;">{{ item.showName }}</span>
                               <span class="ms-2 p-0 m-0 text-muted" style="float:none; position:relative;"
                                      v-sc-tooltip="'Remove'" @click.prevent="deselectUser(item.id)">&times;</span>
                            </button>

                           
                        </div>
                    </div>

                    <div class="row my-2 mt-4">
                        <div class="col-8">
                            <ScSelectWithSearchAndGroups :disabled="dataUpdateSpinner"
                                                         :items="[userGroupsNotSelected, usersNotSelected]"
                                                         :labels="['Groups', 'Users']"
                                                         :emptyAfterSelection="true"
                                                         placeholder="Add Groups or Users with Viewer role"
                                                         @sc-select-item="selectGroupOrUser($event)" >
                            </ScSelectWithSearchAndGroups>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>


<script>

    import $ from 'jquery'
    import ScNotification from '../../../shared/common/ScNotification.vue';
    import ScIcon from '../../../shared/common/ScIcon.vue';
    import ScConfirmModal2 from '../../../shared/common/ScConfirmModal2.vue';
    import ScSelectWithSearchAndGroups from '../../global/ScSelectWithSearchAndGroups.vue';
    import ScToggleOnOff from '../../../shared/common/ScToggleOnOff.vue';
    import MainAppMixin from '../../MainAppMixin';

    let _getDisplayName = function(user) {
      if (user.displayableName === user.email) return user.email;
      return user.displayableName + ' <' + user.email + '>';
    };

    let DataManager = {
        loadData (vm) {
            vm.showLoading = true;
            $.ajax({
                method: 'GET', url: "/main/presentations/ajax_get_manage_access_v2",
                data: {
                    workspace_id: vm.workspaceId,
                    presentation_id: vm.presentationId,
                    pres_action: 'a:view_pres'}
            }).done(function(data){
                vm.permissionType = data.permission_type;
                vm.presIsTemplate = data.is_template;

                vm.users.splice(0);
                for (let item of data.all_users) {
                    if (item.roleCode === 'r:viewer') {
                        vm.users.push({
                            ugType: 'user',
                            id: item.userId,
                            selected: item.selected,
                            showName: _getDisplayName(item),
                            roleCode: item.roleCode
                        });
                    }
                }

                vm.userGroups.splice(0);
                for (let item of data.all_groups) {
                    vm.userGroups.push({
                        ugType: 'group',
                        id: item.userGroupId,
                        selected: item.selected,
                        showName: item.name,
                        // link: '/main/users/list/#group-id/' + item.userGroupId
                    });
                }

            }).fail(function(jqXhr) {
                ScNotification.growlXhrError(jqXhr, "loading data");

            }).always(function() {
                vm.showLoading = false;
            });
        },
        updateData (vm, updateItemId, dataUpdate, isUpdate) {
            vm.dataUpdateSpinner = true;
            ScNotification.growlClear();

            $.ajax({
                method: 'POST', url: "/main/presentations/ajax_save_manage_access_v2",
                data: {
                    workspace_id: vm.workspaceId,
                    presentation_id: vm.presentationId,
                    permission_type: vm.permissionType,
                    pres_action: 'a:view_pres',

                    is_update: isUpdate,
                    update_user: (updateItemId && dataUpdate === 'user') ? updateItemId : 0,
                    update_group: (updateItemId && dataUpdate === 'group') ? updateItemId : 0,
                }
            }).done(function(data){
                if(data.status === 'success') {
                    ScNotification.growlSuccessMsg('Saved');
                    if (vm.permissionType === 'group-read' && !isUpdate) {
                        vm.deselectAll()
                    }
                    // needed if group access modal is open
                    // parameter needed for workshop home presentation publish modal
                    if (vm.$store.state.plist) {
                        vm.$store.commit('plistPresChangePermissionsType', {
                            presId: vm.presentationId, permissions: vm.permissionType });
                    }
                    if (vm.$store.state.pres && vm.$store.state.pres.showcase.presentation.id === vm.presentationId) {
                        vm.$store.commit('presPermissionsUpdate', vm.permissionType);
                    }
                    vm.$emit('changed-pres-access');

                } else {
                    ScNotification.growlErrMsg(data.details);
                }

            }).fail(function() {
                ScNotification.growlErrMsg('An error occurred. Please try again.');

            }).always(function(){
                vm.dataUpdateSpinner = false;
            });
        }

    };

    export default {
        name: 'ManageAccessViewPresentation',
        emits: ['changed-pres-access', 'hide-manage-access'],
        mixins: [MainAppMixin],
        components: {ScToggleOnOff, ScSelectWithSearchAndGroups, ScIcon},
        props: {
            workspaceId: {type: Number, default: null},
            presentationId: {type: Number, default: null}
        },
        data () {
            return {
                presIsTemplate: false,
                permissionType: null,
                users: [],
                userGroups: [],
                showLoading: false,
                dataUpdateSpinner: false
            };
        },
        mounted() {
            DataManager.loadData(this);
        },
        methods: {
            selectGroupOrUser(selectedItem) {
                if (!selectedItem) return;
                if (selectedItem.ugType === 'group') this.selectGroup(selectedItem);
                else this.selectUser(selectedItem);
            },

            selectGroup (e) {
                for (let group of this.userGroups) {
                    if (group.id === e.id) {
                        group.selected = true;
                        DataManager.updateData(this, group.id, 'group', true);
                    }
                }
            },
            deselectGroup (e) {
                if (this.dataUpdateSpinner) return;
                for (let group of this.userGroups) {
                    if (group.id === e) {
                        group.selected = false;
                        DataManager.updateData(this, group.id, 'group', false);
                    }
                }

            },
            selectUser (e) {
                for (let user of this.users) {
                    if (user.id === e.id) {
                        user.selected = true;
                        DataManager.updateData(this, user.id, 'user', true);
                    }
                }
            },
            deselectUser (e) {
                if (this.dataUpdateSpinner) return;
                for (let user of this.users) {
                    if (user.id === e) {
                        user.selected = false;
                        DataManager.updateData(this, user.id, 'user', false);
                    }
                }
            },
            deselectAll() {
                for (let user of this.users) {
                    user.selected = false;
                }
                for (let group of this.userGroups) {
                    group.selected = false;
                }
            },
            changePermission () {
                let isSpecificUsersAndGroups = (this.permissionType !== 'group-read');
                if (isSpecificUsersAndGroups) {
                    ScConfirmModal2.modal('Are you sure?  This will delete the user and group access list.').then(() => {
                        this.permissionType = (isSpecificUsersAndGroups ? 'group-read' : null);
                        DataManager.updateData(this, null, null, false);
                    }, () => {});
                }   else {
                    this.permissionType = (isSpecificUsersAndGroups ? 'group-read' : null);
                    DataManager.updateData(this, null, null, false);
                }
            },
        },
        computed: {
            usersNotSelected: function() {
                return this.users.filter((u) => !u.selected);
            },
            userGroupsNotSelected: function() {
                return this.userGroups.filter((ug) => !ug.selected);
            },
            usersSelected: function() {
                return this.users.filter((u) => u.selected);
            },
            userGroupsSelected: function() {
                return this.userGroups.filter((ug) => ug.selected);
            }
        }
    };




</script>