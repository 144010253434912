<template>
    <div>

        <template v-if="hasVersion === 0">
            <div>
                <strong>{{ partner.name }}</strong>
                <ScIcon v-if="showLoading" name="spinnerFW" class="ms-3 text-muted"/>
            </div>

            <div class="text-muted">Update in progress.  This will occur in the background and may take up to 15 minutes.
                <a href="#" @click.prevent="$emit('refresh-partner-data')">Refresh to check</a>.
            </div>

            <div v-if="publishTimeout" class="text-danger">
                Publish taking longer than one hour.
                <button @click="getPartnersLatestVersion(p)" class="btn btn-outline-secondary">Try again</button>
            </div>
        </template>

        <template v-else>

            <div class="mb-3">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="partner-enabled-1"
                           :checked="enabled" :disabled="showLoading"
                           @click.prevent="togglePartnerEnabled()">
                    <label class="form-check-label" for="partner-enabled-1">
                        <strong>{{ partner.name }}</strong></label>
                </div>

                <div class="ms-4">
                    <div class="d-flex">
                        <div class="my-auto small">Updates sent</div>

                        <div class="form-check my-auto ms-3">
                            <input class="form-check-input" type="radio" id="radio-auto" name="radio" value="auto"
                                   @click="toggleRadio('auto')" :checked="!triggerUpdateManually"
                                   :disabled="showLoading || !enabled">
                            <label class="form-check-label" for="radio-auto">When publishing</label>
                        </div>

                        <div class="form-check my-auto ms-3">
                            <input class="form-check-input" type="radio" id="radio-manual" name="radio" value="manual"
                                   @click="toggleRadio('manual')"
                                   :checked="triggerUpdateManually"
                                   :disabled="showLoading || !enabled">
                            <label class="form-check-label" for="radio-manual">Manually</label>
                        </div>

                        <button class="btn btn-outline-secondary ms-auto" @click.prevent="getPartnersLatestVersion()"
                                :disabled="showLoading || partner.showcase_latest_version === hasVersion || !enabled || !triggerUpdateManually || !partner.showcase_latest_version">
                            Update to latest</button>
                    </div>
                    <div class="mt-2 small" v-if="enabled">
                        <ScIcon name="questionCircleFW" class="text-muted"/>
                        <template v-if="partner.showcase_latest_version">
                            Your version {{ partner.showcase_latest_version }},
                        </template>
                        <template v-else>
                            Your version is unpublished (you need to publish in this workshop before publishing to a partner),
                        </template>

                        <template v-if="hasVersion">
                            partner's version {{ hasVersion }}
                        </template>
                        <template v-else>
                            partner doesn't have it yet
                        </template>

                        <template v-if="partner.showcase_latest_version && (partner.showcase_latest_version === hasVersion)">
                            (same versions)
                        </template>

                        <template v-if="!triggerUpdateManually && (partner.showcase_latest_version !== hasVersion)">
                            <br/><div class="mt-1"><ScIcon name="exclamationTriangleFW" class="text-danger"/> Republish your presentation to send to partner</div>
                        </template>
                    </div>

                </div>
            </div>

        </template>
    </div>


</template>


<script>


    import $ from 'jquery';
    import ScNotification from '../../../shared/common/ScNotification.vue';
    import ScConfirmModal2 from '../../../shared/common/ScConfirmModal2.vue';
    import ScIcon from '../../../shared/common/ScIcon.vue';
    import MainAppMixin from '../../MainAppMixin';

    let DataManager = {

        updateToLatest (vm) {
            vm.showLoading = true;
            ScNotification.growlClear();

            $.ajax({
                type: 'post',
                url: '/main/partners/ajax_partner_publish_latest',
                data: {
                    workspace_id: vm.workspaceId, presentation_id: vm.presentationId,
                    partner_workspace_id: vm.partner.partner_workspace_id
                }
            }).done(() => {
                vm.hasVersion = 0;
                vm.publishTimeout = false;
                vm.$emit('update-partner-to-latest', {
                    partner_workspace_id: vm.partner.partner_workspace_id,
                    partner_has_version: 0,
                    partner_publish_timeout: false
                });

            }).fail((jqXhr) => {
                ScNotification.growlXhrError(jqXhr, 'updating to latest');

            }).always(() => {
                vm.showLoading = false;
            });
        },
        updatePartnerData (vm) {
            vm.showLoading = true;
            ScNotification.growlClear();

            $.ajax({
                type: 'post',
                url: '/main/partners/ajax_partner_rel_put',
                data: {
                    workspace_id: vm.workspaceId, presentation_id: vm.presentationId,
                    partner_workspace_id: vm.partner.partner_workspace_id,
                    partner_enabled: vm.enabled,
                    trigger_update_manually: vm.triggerUpdateManually
                }
            }).done(() => {
                ScNotification.growlSuccessMsg('Saved.');
                vm.$emit('update-partner-data', {
                    partner_workspace_id: vm.partner.partner_workspace_id,
                    partner_enabled: vm.enabled,
                    trigger_update_manually: vm.triggerUpdateManually
                });

            }).fail((jqXhr) => {
                ScNotification.growlXhrError(jqXhr, 'saving partner');

            }).always(() => {
                vm.showLoading = false;
            });
        }
    };



    export default {
        name: 'ManageAccessPartnersPartner',
        emits: ['update-partner-to-latest', 'update-partner-data', 'refresh-partner-data'],
        mixins: [MainAppMixin],
        components: {ScIcon},
        props: {
            partner: {type: Object, default: null},
            workspaceId: {type: Number, default: null},
            presentationId: {type: Number, default: null}
        },
        data () {
            return {
                showLoading: false,

                enabled: false,
                triggerUpdateManually: false,
                hasVersion: 0,
                publishTimeout: true
            };
        },
        mounted () {
            // update data from props
            this.enabled = this.partner.partner_enabled;
            this.triggerUpdateManually = this.partner.trigger_update_manually;
            this.hasVersion = this.partner.partner_has_version;
            this.publishTimeout = this.partner.partner_publish_timeout;
        },
        methods: {
            getPartnersLatestVersion () {
                DataManager.updateToLatest(this);
            },
            togglePartnerEnabled () {
                setTimeout(() => {  // delay
                    if (this.enabled) {
                        ScConfirmModal2.modal('They will lose access, are you sure?').then(() => {
                            ScConfirmModal2.modal('Are you really sure?').then(() => {
                                this.enabled = false;
                                DataManager.updatePartnerData(this);
                            }, () => {});
                        }, () => {});
                    } else {
                        this.enabled = true;
                        DataManager.updatePartnerData(this);
                    }
                });
            },
            toggleRadio (val) {
                this.triggerUpdateManually = (val === "manual");
                DataManager.updatePartnerData(this);
            }
        }

    };





</script>